import { createActions } from '../../utils';

let resources = [
  'auto brands',
  'auto models',
  'auto types',
  'auto years',
  'brands',
  'brands config',
  'plans',
  'plans and providers',
  'insurance providers',
  'insurance providers by character',
  'auto range price',
  'additional benefits',
  'plan and insurance providers',
  'bpjs product',
  'pln product',
],
  otherActions = [
    'update plan compare status',
    'error loading auto range price',
    'error loading plans',
    'loading brands',
    'loading brands config',
    'reset plans',
    'reset additional benefits',
    'error loading additional benefits',
    'reset compare plans',
    'error loading plan and insurance providers',
    'reset nearby providers',
    'error loading bpjs product',
    'error loading pln product',
  ];

export default createActions(resources, otherActions);
